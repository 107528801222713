import React from 'react';
import { useTranslation } from 'react-i18next';

import mapModifiers from 'common/utils/functions';

export type StatusLabelType = 'stopped' | 'isUsing' | 'isStopping';

export const returnModifier = (status: StatusLabelType): StatusLabelType => {
  switch (status) {
    case 'isUsing':
      return 'isUsing';
    case 'stopped':
      return 'stopped';
    default:
      return 'isStopping';
  }
};

type StatusLabelAIServerProps = {
  status: 'stopped' | 'isUsing' | 'isStopping';
  notDot?: boolean;
};

export const StatusLabelAIServer: React.FC<StatusLabelAIServerProps> = ({
  status,
  notDot
}) => {
  const { t } = useTranslation();
  return (
    <span className={mapModifiers('p-statusLabelAIServer')}>
      <span className={mapModifiers('p-statusLabelAIServer_wrapper')}>
        {(() => {
          switch (status) {
            case 'stopped':
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {notDot ? null : <span className={mapModifiers('p-statusLabelAIServer_dot', 'stopped')} />}
                  <span>{t('aiServer.stopped')}</span>
                </span>
              );
            case 'isUsing':
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {notDot ? null : <span className={mapModifiers('p-statusLabelAIServer_dot', 'isUsing')} />}
                  <span>{t('aiServer.using')}</span>
                </span>
              );
            default:
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {notDot ? null : <span className={mapModifiers('p-statusLabelAIServer_dot', 'isStopping')} />}
                  <span>{t('aiServer.isStopping')}</span>
                </span>
              );
          }
        })()}
      </span>
    </span>
  );
};

type StatusOnlineAIServerProps = {
  isOnline: boolean;
  notDot?: boolean;
};

export const StatusOnlineAIServer: React.FC<StatusOnlineAIServerProps> = ({
  isOnline,
  notDot
}) => {
  const { t } = useTranslation();
  return (
    <span className={mapModifiers('p-statusLabelAIServer')}>
      <span className={mapModifiers('p-statusLabelAIServer_wrapper')}>
        {(() => {
          switch (isOnline) {
            case true:
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {notDot ? null : <span className={mapModifiers('p-statusLabelAIServer_dot', 'isOnline')} />}
                  <span>{t('aiServer.online')}</span>
                </span>
              );
            default:
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {notDot ? null : <span className={mapModifiers('p-statusLabelAIServer_dot', 'isOffline')} />}
                  <span>{t('aiServer.offline')}</span>
                </span>
              );
          }
        })()}
      </span>
    </span>
  );
};
