import axiosInstance from '../common/instance';

import {
  ConfigHeaderParams, ConfigHeaderTypes,
  InitialSystemData,
  SaveErrorConfigParams,
  SeoGeneralParamsTypes,
  SeoGeneralTypes,
  SystemGeneralData,
  SystemLocalesData,
  UpdateSystemGeneralParams,
  UpdateSystemLocalesParams
} from './types';

export const getConfigService = async (code: string): Promise<ConfigHeaderTypes> => {
  const res = await axiosInstance.get(`config-${code}`);
  return res.data.data;
};

export const saveConfigService = async (code: string, data: ConfigHeaderParams): Promise<void> => {
  await axiosInstance.post(`config-${code}`, data);
};

export const getSeoGeneralService = async (): Promise<SeoGeneralTypes> => {
  const res = await axiosInstance.get('seo-general');
  return res.data.data;
};

export const postSeoGeneralService = async (params: SeoGeneralParamsTypes) => {
  const res = await axiosInstance.post('seo-general', params);
  return res.data;
};

export const getSystemInitialService = async (): Promise<InitialSystemData> => {
  const res = await axiosInstance.get('initial');
  return res.data.data;
};

export const getSystemLocalesService = async (): Promise<SystemLocalesData> => {
  const res = await axiosInstance.get('system-locales');
  return res.data.data;
};

export const updateSystemLocalesService = async (params: UpdateSystemLocalesParams):
  Promise<void> => {
  await axiosInstance.post('system-locales', params);
};

export const getSystemGeneralService = async ():
  Promise<SystemGeneralData> => {
  const res = await axiosInstance.get('system-general');
  return res.data.data;
};

export const updateSystemGeneralService = async (params: UpdateSystemGeneralParams):
  Promise<void> => {
  const bodyForm = new FormData();
  const gaids = params.gaIds?.split(',');
  gaids?.forEach((item) => {
    bodyForm.append('gaIds[]', item.trim());
  });
  const gtmids = params.gtmIds?.split(',');
  gtmids?.forEach((item) => {
    bodyForm.append('gtmIds[]', item.trim());
  });
  if (params.gMapId) {
    bodyForm.append('gMapId', params.gMapId);
  }

  if (params.fileFavicon) {
    bodyForm.append('fileFavicon', params.fileFavicon);
  }

  if (params.unitPrice) {
    bodyForm.append('unitPrice', String(params.unitPrice));
  }
  if (params.unitPriceDuration) {
    bodyForm.append('unitPriceDuration', String(params.unitPriceDuration));
  }
  if (params.maxDuration) {
    bodyForm.append('maxDuration', String(params.maxDuration));
  }
  if (params.tax) {
    bodyForm.append('tax', String(params.tax));
  }

  if (params.exchangeRate) {
    bodyForm.append('exchangeRate', String(params.exchangeRate));
  }

  if (params.promotionStart) {
    bodyForm.append('promotionStart', String(params.promotionStart));
  }

  if (params.promotionEnd) {
    bodyForm.append('promotionEnd', String(params.promotionEnd));
  }

  if (params.discountPercent) {
    bodyForm.append('discountPercent', String(params.discountPercent));
  }

  bodyForm.append('idFanpageFB', params?.idFanpageFB || '');
  bodyForm.append('idZalo', params?.idZalo || '');

  await axiosInstance.post('system-general', bodyForm);
};

export const getErrorConfigService = async (id: string):
  Promise<ConfigHeaderTypes> => {
  const res = await axiosInstance.get(`errors-pages/${id}`);
  return res.data.data;
};

export const saveErrorConfigService = async (id: string, data: SaveErrorConfigParams):
  Promise<void> => {
  await axiosInstance.post(`errors-pages/${id}`, data);
};
