import {
  DeleteOutlined, EditOutlined, PlusOutlined, StopOutlined,
} from '@ant-design/icons';
import {
  Button,
  Modal, Space, Typography,
  message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {
  useCallback,
  useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { StatusLabelAIServer, StatusOnlineAIServer } from './StatusUsing';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import useNavigateParams from 'common/hooks/useNavigateParams';
import { deleteAIService, getAllAIService, stopServerAIService } from 'common/services/extends/aiServer';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';
import roles from 'configs/roles';

interface ModuleAIServerManagementData {
  id: number,
  code: string,
  name: string,
  apiKey?: string,
  customerUsing?: string,
  createdAt: string,
  updatedAt: string,
  isOnline: boolean,
  isUsing: boolean,
  statusUsing: 'stopped' | 'isUsing' | 'isStopping',
}

const ModuleAIServerManagement: React.FC<ActiveRoles> = ({
  roleDelete, roleCreate, roleUpdate
}) => {
  /* Hook */
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const navigateParams = useNavigateParams();

  /* Selectors */
  const { defaultPageSize } = useAppSelector((state) => state.system);
  const rolesSystem = useAppSelector((state) => state.auth.roles);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  /* React-query */
  const {
    isFetching: listLoading,
    data: listData,
  } = useQuery(
    ['aiServerManagement-list', currentPage, keyword, currentView],
    () => getAllAIService({ page: currentPage, keyword, limit: currentView }),
    { keepPreviousData: true }
  );

  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    ['deleteAIService'],
    async (ids: number[]) => deleteAIService(ids),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(['aiServerManagement-list']);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }

    }
  );

  const { mutate: stopServerMutate, isLoading: stopServerLoading } = useMutation(
    ['stopServerAIService'],
    async (id: number) => stopServerAIService(id),
    {
      onSuccess: () => {
        message.success(t('message.stopServerSuccess'));
        queryClient.invalidateQueries(['aiServerManagement-list']);
      },
      onError: () => {
        message.error(t('message.stopServerFailed'));
      }

    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleDelete = useCallback((data: ModuleAIServerManagementData[]) => {
    deleteMutate(data.map((val) => val.id));
  }, [deleteMutate]);

  const handleStopServer = useCallback((data: ModuleAIServerManagementData) => {
    stopServerMutate(data.id);
  }, [stopServerMutate]);

  /* Datas */
  const columns: ColumnsType<ModuleAIServerManagementData> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: ModuleAIServerManagementData) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- code
    {
      title: t('system.code'),
      dataIndex: 'code',
      key: 'code',
      sorter: {
        compare:
          (
            a: ModuleAIServerManagementData,
            b: ModuleAIServerManagementData
          ) => a.code.localeCompare(b.code),
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ModuleAIServerManagementData) => (
        <Typography.Text>
          {data.code}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (
          a: ModuleAIServerManagementData,
          b: ModuleAIServerManagementData
        ) => a.name.localeCompare(b.name),
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ModuleAIServerManagementData) => (
        <Typography.Text>
          {data.name}
        </Typography.Text>
      ),
    },
    // --- online
    {
      title: t('aiServer.onlineStatus'),
      dataIndex: 'online',
      key: 'online',
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ModuleAIServerManagementData) => (
        <StatusOnlineAIServer isOnline={data.isOnline} />
      )
    },
    // --- customerUsing
    {
      title: t('aiServer.customerUsing'),
      dataIndex: 'customerUsing',
      key: 'customerUsing',
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ModuleAIServerManagementData) => (
        <Typography.Text>
          {data?.customerUsing}
        </Typography.Text>
      )
    },
    // --- Using
    {
      title: t('aiServer.usageStatus'),
      dataIndex: 'using',
      key: 'using',
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ModuleAIServerManagementData) => (
        <StatusLabelAIServer status={data.statusUsing} />
      )
    },
    {
      title: t('aiServer.apiKey'),
      dataIndex: 'apiKey',
      key: 'apiKey',
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ModuleAIServerManagementData) => (
        <Typography.Text>
          {data.apiKey}
        </Typography.Text>
      )
    },
    // --- Tạo lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 250,
      sorter: {
        compare: (a: ModuleAIServerManagementData, b: ModuleAIServerManagementData) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ModuleAIServerManagementData) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 250,
      key: 'updatedAt',
      sorter: {
        compare: (a: ModuleAIServerManagementData, b: ModuleAIServerManagementData) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ModuleAIServerManagementData) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    // --- Stop Server
    {
      title: t('role.stopServer'),
      key: 'stopServer',
      width: 100,
      align: 'center',
      render: (_name: string, _data: ModuleAIServerManagementData) => (
        <Space>
          <Button
            disabled={!rolesSystem.includes(roles.AI_SERVER_STOP_SERVER) && !rolesSystem.includes('*')}
            icon={<StopOutlined />}
            onClick={() => {
              Modal.confirm({
                className: 't-pagetable_deleteRecordModal',
                autoFocusButton: 'cancel',
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                title: t('message.stopServer'),
                onOk: () => {
                  handleStopServer(_data);
                },
              });
            }}
          />
        </Space>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: ModuleAIServerManagementData) => (
        <Space>
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => navigateParams(`${ROUTE_PATHS.AI_SERVER_DETAIL}`, `id=${_data.id}`)}
          />
          <Button
            disabled={!roleDelete}
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                className: 't-pagetable_deleteRecordModal',
                autoFocusButton: 'cancel',
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                title: t('message.confirmDeleteRecord'),
                onOk: () => {
                  handleDelete([_data]);
                },
              });
            }}
          />
        </Space>
      ),
    },
  ]), [t, roleUpdate, rolesSystem, roleDelete, navigateParams, handleDelete, handleStopServer]);

  const tableData = useMemo(() => listData?.data.map((item) => ({
    id: item.aiServersData.id,
    code: item.aiServersData.code,
    name: item.aiServersData.name,
    createdAt: item.aiServersData.createdAt,
    customerUsing: item?.aiServersData?.customerUsing,
    updatedAt: item.aiServersData.updatedAt,
    isOnline: item.aiServersData.isOnline,
    isUsing: item.aiServersData.isUsing,
    statusUsing: item.aiServersData.statusUsing,
    apiKey: item.aiServersData.apiKey,
  })), [listData]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('aiServer.aiServer')}
        rightHeader={(
          <Space>
            <Button
              disabled={!roleCreate}
              type="primary"
              onClick={() => {
                navigate(`${ROUTE_PATHS.AI_SERVER_DETAIL}`);
              }}
            >
              <PlusOutlined />
              {t('system.create')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleDelete={handleDelete}
          isLoading={listLoading || deleteLoading || stopServerLoading}
          handleSearch={handleSearch}
          roles={{
            roleCreate,
            roleDelete,
            roleUpdate
          }}
          tableProps={{
            initShowColumns: ['id', 'code', 'apiKey', 'name', 'online', 'customerUsing', 'using', 'createdAt', 'updatedAt', 'stopServer', 'action'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true
          }}
        />
      </div>
    </>
  );
};

export default ModuleAIServerManagement;
